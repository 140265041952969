@font-face {
	font-family: 'Lato';
	src: url('./Fonts/Lato/Lato-Regular.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: 'Lato-Black';
	src: url('./Fonts/Lato/Lato-Black.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: 'Lato-light';
	src: url('./Fonts/Lato/Lato-Light.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
}
body{
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	font-size:90% !important;
}
.logo{
	height: 110px;
}
.container2-home{
	background-size:cover;
	background-position: center;
	min-height: 666px;
}
.container-hombre-pc{
	width: 100%;
	display: flex;
}
.ciudad{
	margin-bottom: 212px !important;
}
.hombre-pc{
	margin-top: -40px;
	margin-left: auto;
	width: 80%;
	position: relative;
	z-index: 9;
}
.min-height-servicios-home{
	min-height: 1670px;
	background-position:center;
	background-size: cover;
	margin-top: -160px;
	padding-top: 220px !important;
	padding-bottom: 220px;
	position: relative;
	z-index: 99;
}
.bg-top{
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	overflow: hidden;
}
.min-height{
	min-height: 740px;
}
.height{
	min-height: 1300px;
	overflow: hidden;
}
.min-height-2x{
	min-height: 500px;
}
.redes{
  height: 30px;
  width: 30px;
}
.slider h2{
	margin-top: 153px;
	font-size: 58pt;
	font-weight: bold;
	line-height: 2.5rem;
	font-family: "Lato-Black" !important;
}
.card-header{
	background: white;
	color: black;
	font-weight: bold;
	margin-top: 20px;
}
.card-body{
	background: white;
	color: black;
}
.slider h3{
	font-size: 55pt;
	font-family: Lato-Light !important;
}
.divider{
	width: 100%;
	border-bottom: 1px solid white;
}
.btn{
	border-radius: 25rem !important;
}
.btn-primary{
	padding: 10px 40px;
	background-color: #00d45a !important;
	border-color: 1px solid black  !important;
}
.img-noticias{
	width: 100%;
	height: 100%;
}
.icon-contacto{
	width: 34px;
}
.body-contacto{
	padding: 4%;
}
.btn-secondary{
	background-color: #fff !important;
	border-color: #f2f2f2  !important;
	color: #E91F5C !important;
}
.header-contacto{
	background: #00d45a;
	color: white;
}
.redes:hover{
	background:#00d45a;
}
.nav-text-normal
.navbar-nav
.nav-link {
	font-size: 1.2rem ;
    color: #f2f2f2;
	padding: 52px 1.1rem 4px 1.1rem;
	
}
.whatsapp{
	position: fixed;
	right: 40px;
	width: 80px;
	bottom: 20vh;
	z-index: 99999;
}
.nav-text-normal
.navbar-nav
.nav-link:hover {
    color: #00d45a;
}
.text-azul{
	color: #4F83B3;
	font-weight: bold;
}
.text-azul-oscuro{
	color: #220C75;
	font-weight: bold;
}
.title-continer2-home{
	font-size: 6rem;
	line-height: 4.6rem;
	font-size: "Lato-Bold" !important;
}
.subtitle-continer2-home{
	font-size: 7.4rem;
	line-height: 4rem;
	font-family: "Lato-Light" !important;
}
.text-muted {
    color: #7c7c7c !important;
}
.parrafo-container2-home{
	font-size: 1.2rem;
	text-align: justify;
}
.nav-text-white
.navbar-nav
.nav-link {
	font-size: 1.2rem ;
	padding: 52px 1.1rem 4px 1.1rem ;
	font-weight: bold;
}
.text-white>img{
	width: 3.6rem;
}
.border-primary-pgrw{
	padding-top: 52px !important;
	padding-bottom: 4px !important;	
	color: white !important;
	font-weight: bold;
	border-bottom: 8px solid #00d45a !important;
}
.img-cohete{
	width: 50%;
	margin-top: -40%;
}
.title-section{
	font-size: 4.15rem ;
	color: #4F83B3;
}
.title-section-2x{
	font-size: 6.15rem ;
	color: #E91F5C;
}
.computador-servicios{
	position: absolute;
	width: 48%;
	right: 0;
	top: -20px;
}
.center{
	position: relative;
}
.center .text-middle{
	margin: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center .text-middle-quienes-somos{
	margin: 0;
	position: absolute;
	top: 60%;
	left: 25%;
	transform: translate(-25%, -50%);
}

.b-0{
	bottom: 0;
	left: -10%;
}

.b-1{
	bottom: -10%;
	right: -70%;
}

.nav-text-white{
	/*transparente*/
	background-color: rgba(0, 0, 0, .4);
}

.nav-text-white a,
.nav-text-white a:link,
.nav-text-white a:hover,
.nav-text-white a:focus{
	color:#00d45a;
}

footer{
	margin-top: -212px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px 10px;
	flex-wrap: wrap;
}
.margin-footer{
	margin-top: 160px;
	margin-bottom: 60px;
}

.m-negativo{
	margin-top: -180px;
}

.title-quienes-somos{
	font-size: 8rem;
	line-height: 4rem;
}
.title-quienes-somos2{
	font-size: 8rem;
	text-align: right;
	padding-right: 18%;
}
.title-contactenos{
	margin-top: 320px !important;
	font-size: 7rem !important;
}
.redes-footer{
	display: flex;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 40px;
}

.p-100{
	padding-top: 250px;
}

.cometa{
	z-index: 1000;
}

.position-absolute-servicios{
	position: absolute;
	bottom: -300px;
	left: 160px;
}

.bg-primary2 {
  background-color: #E91F5C !important;
	font-size: 14pt;
}

.card {
  border-radius: 1.25rem !important;
}
.fondo_cards_beneficios{
	background: #181818;
}
.btn-dark{
	background: black;
	border-radius: 8px !important;
	padding: 20px 40px !important;
	font-weight: bold;
}
.padding-testimony{
	padding-top: 5rem;
	padding-bottom: 3rem;
}
.img-testimony{
	width: 100%;
	text-align: center;
	padding-bottom: 1rem;
}
.img-testimony>img{
	width: 140px;
}
.text-testimony{
	width: 90%;
	margin: auto;
	border-left: 6px solid white;
	padding: 20px;
}
.fondo_nuestras_sedes{
	background: #77541c;
}
.cards-nosotros{
	margin-top: 160px;
	padding-bottom: 80px;
}
.title-servicios-home{
	font-size: 6rem;
}
.my-card{
	background: #fff;
	border-radius: 8px;
	border-bottom: 12px solid #3C4661;
	color: #7c7c7c;
	margin-top: 20px;
	padding: 40px 40px 20px 40px;
	box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23);
}
.brazo{
	position: absolute;
	left: 0;
	bottom: 500px;
	width: 10.1rem;
}
.img-card-nosotros{
	width: 100%;
}
.title-card{
	line-height: 0.1rem;
	font-size: 1.4rem !important;
	font-weight: bold;
	font-family: 'Lato';
}
.icon-servicios{
	width: 60px;
}
.text-icon-servicios{
	line-height: 2.1rem;
}
.card-porcentajes{
	width: 100%;
	padding: 20px 80px;
	background: white;
	border-radius: 22px;
	color: #535353;
	display: flex;
	align-items: center;
}
.porcentaje{
	font-size: 2rem;
	color: #00d45a;
	font-weight: bold;
}
.texto-porcentajes{
	font-size: 1.1rem;
}
.img-celular{
	position: absolute;
	bottom: -100px;
	left: -100px;
	width: 130%;
}
.img-robot{
	position: absolute;
	right: 30px;
	width: 100px;
	top: -31px;
}
.formulario-contacto {
    width: 90%;
    margin-top: -50px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23);
    border-radius: 22px;
}
.min-height-contacto{
	background-repeat: 'no-repeat';
	background-size: cover;
	background-position: center;
	min-height: 900px;
	position: relative;
	z-index: 99;
	margin-bottom: 190px;
}
.header-formaulario {
    padding: 20px;
    color: #fff;
    background: #3C4661;
    border-radius: 22px 22px 0 0;
    text-align: left;
}
.header-formaulario>h4{
	font-size: 2.6rem;
	line-height: 1.8rem;
}
.formulario {
    width: 100%;
    padding: 30px;
	background: #F3F3F3;
	border-radius: 0 0 22px 22px;
}
.input {
    width: 100%;
    padding: 8px;
    border: none;
	border-bottom: 1px solid#6c757d;
    font-size: .8rem;
    outline: none;
	background: transparent;
}
.text-contactos{
	color: #00d45a;
	font-size: .8rem;
}
.bordes{
	border-left: 1px solid #EBEBEB;
	border-right: 1px solid #EBEBEB;
}
.icon-footer{
	width: 20px;
}
.logo-footer{
	width:200px;
}
@media (min-width: 1400px){
	.min-height{
		min-height: 840px;
	}
	.center .text-middle {
		display: block;
		margin-top: 26%;
		margin-left: 12%;
	}
	.title-section-2x{
		font-size: 9rem;
		font-weight: bold;
		margin-bottom: 3rem;
	}

	.slider h2 {
		margin-top: 206px;
	}
	.btn-group-lg > .btn, .btn-lg {
		font-size: 2rem;
	}
	.title-contactenos{
		margin-top: 371px !important;
		font-size: 7rem !important;
	}
}
@media (max-width: 1160px){
	.title-continer2-home{
		font-size: 5rem;
		line-height: 3rem;
	}
	.subtitle-continer2-home{
		font-size: 5rem;
		line-height: 3rem;
	}
	.title-section{
		font-size: 2.8rem !important;
		line-height: 2.4rem !important;
	}
}
/*@media (max-width:770px) {
	.container-servicios-home{
		margin-top: 40px !important;
		margin-bottom: 100px !important;
	}
	.margin-footer{
		margin-top: 80px !important;
		margin-bottom: 10px !important;
	}
	.redes-footer{
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		line-height: .8rem;
	}
	.min-height-servicios-home{
		background-position:center;
	}
}
@media (max-width:670px){
	.padding-movil{
		padding: 40px 10px 120px 10px;
	}
	
	.intro-movil>h2{
		font-size: 3rem;
	}
	.intro-movil>h3{
		font-size: 2.6rem;
	}
	.cards-movil{
		padding: 0 !important;
	}
	.contact-movil{
		padding: 100px 10px !important;
	}
	.formulario-contacto{
		width: 100%;
		margin-top: 40px;
	}
	input,textarea{
		font-size: .8rem !important;
	}
	.nav-text-normal .navbar-nav .nav-link{
		padding: 10px !important;
	}
	.nav-text-white .navbar-nav .nav-link{
		padding: 10px !important;
	}
	.navbar{
		padding: 0 !important;
	}
	.navbar-collapse{
		background: rgba(0,0,0,.1);
	}
	.navbar-brand{
		padding: .5rem;
	}
	.navbar-toggler{
		padding: 1.1rem;
	}
}*/

@media (max-width: 990px) {
	.nav-text-normal .navbar-nav .nav-link{
		padding: 22px 1.1rem 4px 1.1rem;
	}
	.border-primary-pgrw {
		padding-top: 22px !important;
	}
	.nav-text-white .navbar-nav .nav-link{
		padding: 22px 1.1rem 4px 1.1rem;
	}
	.navbar{
		padding: 0 !important;
	}
	.slider h2 {
		margin-top: 166px;
		font-size: 33pt;
		font-weight: bold;
	}
	.title-continer2-home {
		font-size: 3rem;
		line-height: 2.6rem;
	}
	.title-servicios-home {
		font-size: 3rem;
	}
	.computador-servicios{
		display: none;
	}
	.computador-servicios2{
		width: 100%;
		padding-right: -15px !important;
	}
	.navbar-dark .navbar-brand {
		padding: 0 !important;
		margin: 0 !important;
	}
	.logo {
		height: 90px;
		margin-left: 1.5rem;
	}
	.navbar-toggler{
		margin-right: 1.5rem;
	}
	.slider h3{
		font-size: 25pt;
	}
	.title-quienes-somos {
		font-size: 3rem;
		line-height: 0rem !important;
	}
	.p-movil{
		padding-top: 200px;
		padding-bottom: 500px;
	}
	.title-quienes-somos2 {
		font-size: 3rem;
		text-align: right;
		padding-right: 0 !important;
	}
	.img-cohete{
		width: 150%;
		margin-top: -80%;
	}

	.navbar-dark .navbar-nav .nav-link {
		margin-top: 0px;
		font-size: 1rem ;
	    color: white !important;
		font-weight: bold;
		padding-right: 2rem !important;
		padding-left: 2rem !important;
	}

	.navbar-nav{
		background-color: rgba(0, 0, 0, .8);
		margin: 0 !important;
	}

	.title-section-2x{
		font-size: 2.15rem ;
		color: #E91F5C;
	}

	.min-height-2x{
		min-height: 800px;

	}

	.center .text-middle{
	  margin: 0;
	  position: absolute;
	  top: 95%;
	  left: 0%;
	  transform: translate(15%, -50%);
	}

	.text-middle div.title-sm{
		font-size: 16px;
	}

	.m-negativo{
		margin-top: 0;
	}
	.margin-movil{
		margin-bottom: 40px;
		margin-top: 40px;
	}
	.min-height-contacto {
		margin-bottom: 180px;
	}
	.p-movil {
		margin-bottom: 170px !important;
	}
	.title-contactenos {
		margin-top: 190px !important;
		font-size: 4rem !important;
	}
}
@media(max-width:440px) { 
	.title-contactenos {
		margin-top: 355px !important;
		font-size: 2.6rem !important;
	}
}
.text-regular{
	font-family: "Lato" !important;
}